@keyframes infinite-scale {
	0%,
	100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.1);
	}
}

.infinite-scale {
	animation: infinite-scale 2s infinite;
}

@keyframes up-down {
	0%,
	100% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-10px);
	}
}

.up-down {
	animation: up-down 2s infinite;
}
