section {
	scroll-snap-type: y mandatory;
	overflow-y: scroll;
	height: 100vh;
}

section > div {
	position: relative;
	scroll-snap-align: center;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: white;
	border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	/* margin: 10px; */
	background: gray;
}
