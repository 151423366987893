@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allura&family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  font-display: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e4dacf;
  text-align: center;
  min-height: 100vh; /* Disable scrolling */
  height: -webkit-fill-available;
  -webkit-text-size-adjust: 100%;
}

p,
h1,
h2,
h3,
h4,
h5 {
  padding: 0;
  margin: 0;
}

input,
textarea,
select,
button {
  font-size: 16px; /* Ukuran font minimal */
  -webkit-appearance: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  .section-gap {
    @apply flex flex-col md:gap-[6vh];
  }
  .footer {
    @apply mx-auto px-[64px];
  }
  .h2 {
    @apply text-5xl;
  }
  .section {
    @apply mx-auto px-5 py-10 md:py-[11vh] md:px-[120px];
  }
  .container {
    @apply mx-auto;
  }
}
