section {
  scroll-snap-type: y mandatory;
}

section > div {
  position: relative;
  scroll-snap-align: center;
}

.custom-scrollbar2::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar2::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1); /* White color with 50% opacity */
  border-radius: 6px;
}

.custom-scrollbar2::-webkit-scrollbar-track {
  margin: 10px;
  /* background: gray; */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  margin: 10px;
  /* background: gray; */
}

.next,
.prev {
  top: calc(50% - 20px);
  position: absolute;
  background: white;
  border-radius: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  z-index: 2;
}

.next {
  right: 10px;
}

.prev {
  left: 10px;
  transform: scale(-1);
}
