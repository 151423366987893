.bgResponsive {
	background-size: cover;
	background-position: center;
	background-color: var(--bg-color);
	background-image: var(--desktop-bg);
}

@media (max-width: 768px) {
	.bgResponsive {
		background-image: var(--mobile-bg);
	}
}
